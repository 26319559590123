<mat-card
  [ngClass]="{
    'card-nichtZugeordnet': katalog().status === 'NotAssigned',
    'card-aktiv': katalog().status === 'Assigned'
  }"
>
  <div class="card-header">
    <div class="card-header-main">
      <div class="katalog-info-row">
        <div class="katalog-name">
          <span>Katalog:</span>
          <span class="value truncate">{{ katalog().name }}</span>
        </div>

        <div class="katalog-quelle">
          <span>Quelle:</span>
          <span class="value truncate">{{ katalog().dataSource }}</span>
        </div>
      </div>
      @if (katalog().assignedOrganizations?.length) {
        <div class="katalog-info-row">
          <div class="katalog-organisationen">
            <span>Organisationen:</span>
            <span class="value">
              @for (org of katalog().assignedOrganizations; track org.organizationId; let first = $first) {
                @if (!first) {
                  ,
                }
                <span [class.katalog-organisation-removed]="org.isRemoved">{{ org.name }}</span>
              }
            </span>
          </div>
        </div>
      }

      @if (canSave) {
        <mpsm-catalog-supplier-selector
          [editMode]="editMode && canWrite"
          [lieferant]="katalog().supplierReference"
          [katalogtyp]="katalog().type"
          (lieferantChanges)="onLieferantChanges($event)"
        />
      }
    </div>

    <div class="card-header-aside">
      <div class="last-import">
        <p class="truncate">Letzter Import</p>
        <p class="value">
          {{ katalog().lastStaging | humanizeRelativeDate: { whenInRange: { days: 2 } } }}
        </p>
      </div>
    </div>
  </div>

  <mat-card-footer class="card-footer">
    <div class="katalog-info">
      <div class="katalog-info-main">
        <div class="artikel-count">
          <span class="value">{{ katalog().articleTotalCount ?? 0 | number }}</span>
          <span>Artikel {{ articleHasPictures() ? ', davon ' : '' }}</span
          >&nbsp;
          @if (articleHasPictures()) {
            <span class="value">{{ katalog().articleWithPicturesCount ?? 0 | number }}</span>
            <span>mit Bildern</span>
          }
        </div>

        @if (catalogHasValidCovinRate()) {
          <mp-catalog-covin-badge [value]="katalog().covinRate" />
        }
      </div>

      <div class="card-footer">
        <div class="card-footer-tasks-info">
          @for (taskInfo of katalog().openTasks | keyvalue: orginalSorting; track taskInfo) {
            @if (taskInfo.value) {
              <mp-task-info [icon]="openTaskIconMap[taskInfo.key]" [openTask]="taskInfo.value" />
            }
          }
        </div>
        <div class="card-footer-action">
          @if (!katalog().supplierReference && canSave) {
            <button
              mat-flat-button
              color="accent"
              class="btn--save"
              [disabled]="!(canWrite && katalog().status === 'NotAssigned' && !!assignedLieferant)"
              (click)="onSpeichernClicked(katalog().supplierReference?.supplierId)"
            >
              <mat-icon>check</mat-icon>
              <span>Speichern</span>
            </button>
          }

          <button mat-stroked-button class="btn--detail" [disabled]="!hasDetails" (click)="emitKatalogClicked()">
            <span>Details</span>
          </button>
        </div>
      </div>
    </div>
  </mat-card-footer>
</mat-card>
